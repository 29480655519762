@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
     font-family: 'GeoMedium';
     src: local('GeoMedium'),
          url('./fonts/GeogrotesqueCyr-Medium.ttf') format('truetype');
     font-weight: 400;
}

@font-face {
     font-family: 'SemiBold';
     src: local('SemiBold'),
          url('./fonts/GeogrotesqueCyr-SemiBold.ttf') format('truetype');
     font-weight: 600;
}

@font-face {
     font-family: 'GeoBold';
     src: local('GeoBold'),
          url('./fonts/GeogrotesqueCyr-Bold.ttf') format('truetype');
     font-weight: 700;
}

:root {
     --yellow: #f0b500;
}

body {
     font-family: 'GeoMedium';
}

.font-medium {
     font-family: 'GeoMedium';
}
.font-semibold {
     font-family: 'SemiBold';
}
.font-bold {
     font-family: 'GeoBold';
}
@layer components {
     p {
          @apply text-xl tablet:text-lg font-normal !leading-6;
     }
     ul {
          @apply text-xl tablet:text-lg font-normal !leading-6;
     }
}
a.active::before {
     content: '//';
     margin-right: 0.35rem;
     color: var(--yellow);
     font-weight: bold;
}
a.logo::before {
     margin: 0;
     content: '';
}
h1,
h2,
h3,
h4,
h5,
h6 {
     font-family: 'SemiBold';
}

.container {
     max-width: 1440px !important;
}
.hero {
     background-image: url(./assets/bg-hero.jpg);
     background-size: 100% 600px;
     background-repeat: no-repeat;
}
.clip {
     clip-path: polygon(0% 0%, 100% 0%, 85% 100%, 0 100%);
}

.wistia_responsive_padding {
     position: initial !important;
}